import { SignInButton, useClerk, useUser } from "@clerk/clerk-react";
import React, { useEffect } from "react";
import "./App.css";

import {
  Link,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AirtableData2 from "./AirtableData_2";
import InfoPage from "./infopage";
import WelcomeMessage from "./welcome_message";

const Navbar = () => {
  const { isSignedIn } = useUser();
  const { signOut } = useClerk();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignedIn && location.pathname !== "/infos") {
      navigate("/home");
    }
  }, [isSignedIn, navigate]);

  const onLogout = async () => {
    await signOut();
    navigate("/");
  };

  // Déterminer si l'utilisateur est sur la page 'infos' ou la page d'accueil
  const isOnHomePage = location.pathname === "/home";
  const buttonText = isOnHomePage ? "Infos" : "Home";
  const buttonPath = isOnHomePage ? "/infos" : "/home";

  return (
    <nav className="flex justify-between items-center p-4 bg-rose-200 text-gray-700  shadow-md">
      {/* <nav className="flex justify-between items-center p-4 bg-rose-200 text-gray-700  w-full  border-gray-200 dark:border-gray-600  shadow-md"> */}
      {/* <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600"> */}
      {/* <div className="text-xl font-semibold font-poppins">Agathe & Yann ❤️</div> */}

      <div className="text-2xl font-extrabold font-poppins">
        <i className="fa fa-heart text-red-500 mr-2"></i> Agathe & Yann
      </div>

      <div className="flex space-x-4 font-poppins">
        {isSignedIn && (
          <Link
            to={buttonPath}
            className="px-4 py-2 bg-rose-500 text-white rounded-md shadow-md hover:bg-rose-600"
          >
            <i className="fa fa-info-circle mr-2"></i> {buttonText}
          </Link>
        )}
        {/* {isSignedIn ? (
          <button onClick={onLogout} className="px-4 py-2 bg-gray-500 text-white rounded-md shadow hover:bg-gray-600">
            Log out
          </button>
        ) : (
          <SignInButton className="px-4 py-2 bg-green-500 text-white rounded-md shadow hover:bg-green-600">Log in</SignInButton>
        )} */}

        {isSignedIn ? (
          <button
            onClick={onLogout}
            className="px-4 py-2 bg-gray-500 text-white rounded-md shadow-md hover:bg-gray-600"
          >
            <i className="fa fa-sign-out mr-2"></i> Log out
          </button>
        ) : (
          <SignInButton className="px-4 py-2 bg-green-500 text-white rounded-md shadow hover:bg-green-600">
            Log in
          </SignInButton>
        )}
      </div>
    </nav>
  );
};

function App() {
  const { user } = useUser();

  const userEmail = user?.primaryEmailAddress?.emailAddress;

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<WelcomeMessage />} />
        <Route path="/home" element={<AirtableData2 userEmail={userEmail} />} />
        <Route path="/infos" element={<InfoPage />} />
      </Routes>
    </Router>
  );
}

export default App;
