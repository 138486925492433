import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
// const InfoPage = () => {
//   return (
//     <div>
//       <h2>Informations sur le mariage</h2>
//       <p>Agathe et Yann ont la joie de vous annoncer leur mariage...</p>
//       {/* Ajoutez ici le contenu détaillé et la photo */}
//       {/* <Link to="/home" className="return-button">Retour aux Défis</Link> */}

//     </div>
//   );
// };
// const InfoPage = () => {
//   return (
//     <div className="container mx-auto p-4 grid grid-cols-1 lg:grid-cols-2 gap-8">
//       <div className="bg-white rounded-lg shadow-lg p-6">
//         <h2 className="text-3xl font-semibold mb-4 text-blue-700">Agathe et Yann</h2>
//         <p className="text-lg mb-4 text-gray-800">Ont la joie de vous annoncer leur mariage</p>
//         <p className="text-lg mb-4 text-gray-800">qui sera célébré lors d'une cérémonie laïque</p>
//         <p className="text-lg mb-6 text-gray-800"><span className="font-bold text-red-600">Le 28/12/2024</span></p>
//         <p className="text-lg mb-6 text-gray-800">Au <span className="font-bold text-purple-700">Domaine de La Croix des Champs</span> au Veyrier du Lac</p>
//         <p className="text-lg mb-6 text-gray-800">La fête se poursuivra jusqu'au <span className="font-bold text-red-600">30/12/2024</span> autour de nombreuses surprises</p>
//       </div>
//       <div className="bg-white rounded-lg shadow-lg p-6">
//         <img src="./image.png" alt="Image mariage" className="w-full max-w-lg mx-auto mb-4" />
//         <p className="text-lg mb-4 text-gray-800">Mais vous le savez, Agathe et Yann sont joueurs,</p>
//         <p className="text-lg mb-4 text-gray-800">Alors à vous de jouer pour tenter de remporter votre dossard pour cet heureux marathon au domaine de La Croix des Champs</p>
//         <p className="text-lg mb-4 text-gray-800">Sportifs, gourmands, créatifs… à chacun ses défis ! Restez connectés, des défis vous seront régulièrement proposés sur des périodes définies : soyez attentifs ! Chacun devra en valider au moins 3 avant le <span className="font-bold text-red-600">31.08.2024</span></p>
//         <p className="text-lg mb-4 text-gray-800">Un seul défi commun : nous répondre avant le <span className="font-bold text-red-600">30.06.2024</span> pour tout ou partie des festivités.</p>
//         <p className="text-lg mb-4 text-gray-800">Si malheureusement vous savez déjà que votre participation au mariage olympique est compromise, n'hésitez pas à nous le faire savoir le plus rapidement possible 😊</p>
//       </div>
//     </div>
//   );
// };
// export default InfoPage;



// const InfoPage = () => {
//   return (
//     <div className="container mx-auto p-4">
//       <div className="md:flex md:items-center">
//         <div className="md:w-1/2 md:mr-8">
//           <img src="./image.png" alt="Image mariage" className="w-full max-w-lg mx-auto mb-4" />
//         </div>
//         <div className="md:w-1/2">
//           <h2 className="text-3xl font-semibold mb-4 text-blue-700">Agathe et Yann</h2>
//           <p className="text-lg mb-4 text-gray-800">Ont la joie de vous annoncer leur mariage</p>
//           <p className="text-lg mb-4 text-gray-800">qui sera célébré lors d'une cérémonie laïque</p>
//           <p className="text-lg mb-6 text-gray-800"><span className="font-bold text-red-600">Le 28/12/2024</span></p>
//           <p className="text-lg mb-6 text-gray-800">Au <span className="font-bold text-purple-700">Domaine de La Croix des Champs</span> au Veyrier du Lac</p>
//           <p className="text-lg mb-6 text-gray-800">La fête se poursuivra jusqu'au <span className="font-bold text-red-600">30/12/2024</span> autour de nombreuses surprises</p>
//           <p className="text-lg mb-4 text-gray-800">Mais vous le savez, Agathe et Yann sont joueurs,</p>
//           <p className="text-lg mb-4 text-gray-800">Alors à vous de jouer pour tenter de remporter votre dossard pour cet heureux marathon au domaine de La Croix des Champs</p>
//           <p className="text-lg mb-4 text-gray-800">Sportifs, gourmands, créatifs… à chacun ses défis ! Restez connectés, des défis vous seront régulièrement proposés sur des périodes définies : soyez attentifs ! Chacun devra en valider au moins 3 avant le <span className="font-bold text-red-600">31.08.2024</span></p>
//           <p className="text-lg mb-4 text-gray-800">Un seul défi commun : nous répondre avant le <span className="font-bold text-red-600">30.06.2024</span> pour tout ou partie des festivités.</p>
//           <p className="text-lg mb-4 text-gray-800">Si malheureusement vous savez déjà que votre participation au mariage olympique est compromise, n'hésitez pas à nous le faire savoir le plus rapidement possible 😊</p>
//         </div>
//       </div>
//     </div>
//   );
// };

const InfoPage = () => {
  return (
    <div className="container mx-auto p-4">
      <Helmet>
        <link
          rel="preconnect"
          href="https://fonts.googleapis.com"
        />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossorigin
        />
        <link
          href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&family=Oswald&family=Poppins&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="md:flex md:items-center">
        <div className="md:w-1/2 md:mr-8">
          <img src="./image.png" alt="Image mariage" className="w-full max-w-lg mx-auto mb-4" />
        </div>
        <div className="md:w-1/2  text-justify">
          <h2 className="text-3xl font-semibold mb-4 text-green-900  text-center">Agathe et Yann ...</h2>
          <p className="text-lg mb-4 text-gray-800 font-poppins">... Ont la joie de vous annoncer leur mariage</p>
          <p className="text-lg mb-4 text-gray-800 font-poppins">... qui sera célébré lors d'une cérémonie laïque</p>
          <p className="text-lg mb-6 text-gray-800  font-poppins"><span className="font-bold text-green-600">Le 28.12.2024</span></p>
          <p className="text-lg mb-6 text-gray-800  font-poppins">Au <span className="font-bold text-green-400">Domaine de La Croix des Champs</span> au Veyrier du Lac</p>
          <p className="text-lg mb-6 text-gray-800  font-poppins">La fête se poursuivra jusqu'au <span className="font-bold text-green-600">30.12.2024</span> autour de nombreuses surprises</p>
          <p className="text-lg mb-4 text-gray-800  font-poppins">Mais vous le savez, Agathe et Yann sont joueurs,</p>
          <p className="text-lg mb-4 text-gray-800  font-poppins">Alors à vous de jouer pour tenter de remporter votre dossard pour cet heureux marathon au domaine de La Croix des Champs</p>
          <p className="text-lg mb-4 text-gray-800  font-poppins">Sportifs, gourmands, créatifs… à chacun ses défis ! Restez connectés, des défis vous seront régulièrement proposés sur des périodes définies : soyez attentifs ! Chacun devra en valider au moins 3 avant le <span className="font-bold text-green-600">31.08.2024</span></p>
          <p className="text-lg mb-4 text-gray-800  font-poppins">Un seul défi commun : nous répondre avant le <span className="font-bold text-green-600">30.06.2024</span> pour tout ou partie des festivités.</p>
          <p className="text-lg mb-4 text-gray-800  font-poppins">Si malheureusement vous savez déjà que votre participation au mariage olympique est compromise, n'hésitez pas à nous le faire savoir le plus rapidement possible 😊</p>
        </div>
      </div>
    </div>
  );
};

export default InfoPage;

// Agathe et Yann ...: vert fonvé 062B16
// les dates: 095228
// le domaine de la croix des champs: 689D71


