import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import "./AirtableData_2.css";
// import cloudinary from "cloudinary/lib/cloudinary";
import { PlusIcon, MinusIcon } from "@heroicons/react/outline";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";

const airtable_personal_access_token = process.env.REACT_APP_AIRTABLE;
const Utilisateurs_table_id = "tbl1pMn0FJEm3JRsk";
const Challenges_table_id = "tblHVGKW2extpavfE";
const Soumission_challenges_table_id = "tblJHHrD3oji5AjCl";

// cloudinary.config({
//   cloud_name: "dz4uuuziy",
//   api_key: "265642213225632",
//   api_secret: "-Z0tVqhA2OUH6Q_DwZ92Fsqq-y4"
// });

const AirtableData2 = ({ userEmail }) => {
  const [challenges, setChallenges] = useState([]);
  const [userSubmissions, setUserSubmissions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [photoFile, setPhotoFile] = useState(null);
  const [showAvailableChallenges, setShowAvailableChallenges] = useState(true);
  const [showSubmittedChallenges, setShowSubmittedChallenges] = useState(false);
  const [showValidatedChallenges, setShowValidatedChallenges] = useState(false);

  const airtableInstance = axios.create({
    baseURL: `https://api.airtable.com/v0/app2RVQ9pyA2joxqi/`,
    headers: {
      Authorization: `Bearer ${airtable_personal_access_token}`,
    },
  });

  useEffect(() => {
    if (!userEmail) return;

    // Charger tous les challenges depuis Airtable
    airtableInstance.get(`${Challenges_table_id}`).then((response) => {
      setChallenges(response.data.records);
    });

    // Charger les soumissions de l'utilisateur depuis Airtable
    airtableInstance
      .get(`${Soumission_challenges_table_id}`, {
        params: {
          filterByFormula: `{Utilisateur} = '${userEmail}'`,
        },
      })
      .then((response) => {
        setUserSubmissions(response.data.records);
      });
    // }, []);
  }, [userEmail]);

  // Filtrer les challenges en trois catégories
  const challengesDisponibles = challenges.filter((challenge) => {
    const startDate = moment(challenge.fields["date_debut"]);
    const endDate = moment(challenge.fields["date_fin"]);
    const isAvailable = moment().isBetween(startDate, endDate);

    const isSubmitted = userSubmissions.some(
      (submission) => submission.fields["Challenge"][0] === challenge.id
    );

    // Vérifiez si le challenge a été validé par l'utilisateur
    const isValidated = userSubmissions.some(
      (submission) =>
        submission.fields["Challenge"][0] === challenge.id &&
        submission.fields["Statut"] === "validé"
    );

    return isAvailable && !isSubmitted && !isValidated;
  });

  const challengesSoumis = userSubmissions.filter(
    (submission) => submission.fields["Statut"] === "soumis"
  );
  const challengesValidés = userSubmissions.filter(
    (submission) => submission.fields["Statut"] === "validé"
  );

  // Fonction pour obtenir le nom du challenge associé à une soumission
  const getChallengeName = (submission) => {
    console.log("submission", submission);
    const challengeId = submission.fields["Challenge"][0];
    console.log("challengeId", challengeId);
    const matchingChallenge = challengeId
      ? challenges.find((challenge) => challenge.id === challengeId)
      : null;
    console.log("matchingChallenge", matchingChallenge);
    return matchingChallenge
      ? matchingChallenge.fields.nom
      : "Nom de challenge introuvable";
  };

  const handleOpenModal = (challenge) => {
    setSelectedChallenge(challenge);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedChallenge(null);
    setIsModalOpen(false);
    setPhotoFile(null);
  };

  const getUserRecordId = async (userEmail) => {
    try {
      const response = await airtableInstance.get(`${Utilisateurs_table_id}`, {
        params: {
          filterByFormula: `{Email} = '${userEmail}'`,
        },
      });
      const records = response.data.records;
      if (records.length > 0) {
        return records[0].id; // Retourne l'ID du premier utilisateur trouvé
      } else {
        console.error("Aucun utilisateur trouvé avec cet e-mail");
        return null;
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de l'utilisateur :", error);
      return null;
    }
  };

  const handleDeleteImage = async (publicId) => {
    // const cloudName = 'dz4uuuziy';
    // const apiKey = '265642213225632';
    // const apiSecret = '-Z0tVqhA2OUH6Q_DwZ92Fsqq-y4';
    // const timestamp = new Date().getTime();
    // // const signature = generateSHA1(generateSignature(publicId, timestamp, apiSecret));
    // const url = `https://api.cloudinary.com/v1_1/${cloudName}/image/destroy`;
    // cloudinary.v2.uploader.destroy(publicId, function(error,result) {
    //   console.log(result, error) })
    //   .then(resp => console.log(resp))
    //   .catch(_err=> console.log("Something went wrong, please try again later."));
    // try {
    //   const response = await axios.post(url, {
    //     public_id: publicId,
    //     // signature: signature,
    //     api_key: apiKey,
    //     timestamp: timestamp,
    //   });
    //   console.log("Image supprimée avec succès de Cloudinary:", response);
    // } catch (error) {
    //   console.error("Erreur lors de la suppression de l'image:", error);
    // }
  };

  const handlePhotoUpload = async () => {
    if (!selectedChallenge.id || !userEmail || !photoFile) {
      console.error("Données manquantes pour la soumission");
      return;
    }

    const userId = await getUserRecordId(userEmail);
    console.log("userId", userId);

    // Début de l'upload de l'image sur Cloudinary
    const formData = new FormData();
    formData.append("file", photoFile);
    formData.append("upload_preset", "xdyzc9vg"); // Remplacez 'votre_preset' par votre preset Cloudinary
    formData.append("folder", "app-yann"); // Optionnel: nom du dossier sur Cloudinary

    const axioscloudinaryConfig = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer 265642213225632",
      },
    };

    // const uploadResponse = await axios.post(
    //   "https://api.cloudinary.com/v1_1/v1692820991/image/upload",
    //   formData
    // );
    let uploadResponse;
    try {
      uploadResponse = await axios.post(
        "https://api.cloudinary.com/v1_1/dz4uuuziy/image/upload",
        formData
        // axioscloudinaryConfig
      );
      console.log("uploadResponse", uploadResponse);
    } catch (error) {
      console.error("Error uploading to Cloudinary:", error);
      return;
    }

    console.log("first uploadResponse", uploadResponse);

    const imageUrl = uploadResponse.data.secure_url; // URL de l'image hébergée sur Cloudinary
    const publicId = uploadResponse.data.public_id;

    // Préparation de l'objet data pour l'API Airtable
    const data = {
      records: [
        {
          fields: {
            Challenge: [selectedChallenge.id], // Assurez-vous que c'est un tableau d'IDs
            Utilisateur: [userId], // Assurez-vous que c'est un tableau d'Emails
            Statut: "soumis",
            // PreuveRealisation: "https://upload.wikimedia.org/wikipedia/commons/3/34/Transparent_Background_Dromedary_Camel.png"
            PreuveRealisation: [
              {
                // url: "https://upload.wikimedia.org/wikipedia/commons/3/34/Transparent_Background_Dromedary_Camel.png",
                url: imageUrl,
              },
            ],
          },
        },
      ],
    };

    airtableInstance
      .post(`${Soumission_challenges_table_id}`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response", response);
        // Mettre à jour les soumissions de l'utilisateur
        const newSubmission = response.data.records[0];

        // Ajouter le nouvel enregistrement au tableau existant de soumissions
        setUserSubmissions([...userSubmissions, newSubmission]);

        handleCloseModal();

        // Suppression de l'image de Cloudinary après la confirmation d'Airtable
        // handleDeleteImage(publicId);
      })
      .catch((error) => {
        console.log(error);
        console.error("Erreur lors de l'envoi de la soumission :", error);
      });
  };

  const ChallengesSoumisList = ({ challenges, getChallengeName }) => {
    // return (

    //   <div>
    //     {challenges.map((submission) => (
    //       <div key={submission.id}>
    //         <h3>Nom du Challenge: {getChallengeName(submission)}</h3>
    //         <p>Description: {submission.fields.description}</p>
    //         {/* Autres détails de soumission */}
    //       </div>
    //     ))}
    //   </div>

    return (
      <>
        <div className="sm:hidden  font-poppins">
          {challenges.map((submission) => {
            return (
              <div
                key={submission.id}
                className="p-4 border-b bg-white shadow-sm rounded-lg m-2"
              >
                <h3>Nom du Challenge: {getChallengeName(submission)}</h3>
                {/* <p>Description: {submission.fields.description}</p> */}
              </div>
            );
          })}
        </div>

        <div className="hidden sm:block overflow-x-auto  font-poppins">
          <table className="min-w-full table-auto">
            <thead className="bg-pink-100">
              <tr>
                <th className="px-4 py-2 text-left">Nom</th>
                {/* <th className="px-4 py-2 text-left">Description</th> */}
              </tr>
            </thead>

            <tbody>
              {challenges.map((submission) => {
                return (
                  <tr
                    key={submission.id}
                    className="cursor-pointer hover:bg-gray-100 hover:bg-pink-100"
                  >
                    <td className="border px-4 py-2">
                      {getChallengeName(submission)}
                    </td>
                    {/* TODO: gerer l'overflow de texte */}
                    {/* <td className="border px-4 py-2">
                    {submission.fields.description}
                  </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
    // );
  };

  return (
    // <div className="container mx-auto px-4 sm:px-6 lg:px-8 bg-gray-100">
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 bg-gray-100 mt-4">
      <h2
        className="bg-custom-pink-entete text-custom-pink-entete p-2 mb-2 mt-2 rounded-lg cursor-pointer font-poppins flex items-center"
        onClick={() => setShowAvailableChallenges(!showAvailableChallenges)}
      >
        {showAvailableChallenges ? (
          <MinusCircleIcon className="h-5 w-5 mr-2" />
        ) : (
          <PlusCircleIcon className="h-5 w-5 mr-2" />
        )}
        Challenges Disponibles
      </h2>

      {showAvailableChallenges && (
        <ChallengesList
          challenges={challengesDisponibles}
          onOpenModal={handleOpenModal}
        />
      )}

      {/* <ChallengesList
        challenges={challengesDisponibles}
        onOpenModal={handleOpenModal}
      /> */}

      {isModalOpen && selectedChallenge && (
        <ModalChallenge
          challenge={selectedChallenge}
          onClose={handleCloseModal}
          onPhotoUpload={handlePhotoUpload}
          setPhotoFile={setPhotoFile}
          isModalOpen={isModalOpen} // Passez isModalOpen comme une prop
        />
      )}

      <h2
        className="bg-custom-pink-entete text-custom-pink-entete  p-2 mb-2 mt-2 rounded-lg cursor-pointer  font-poppins flex items-center"
        onClick={() => setShowSubmittedChallenges(!showSubmittedChallenges)}
      >
        {showSubmittedChallenges ? (
          <MinusCircleIcon className="h-5 w-5 mr-2" />
        ) : (
          <PlusCircleIcon className="h-5 w-5 mr-2" />
        )}
        Challenges Soumis
      </h2>
      {showSubmittedChallenges && (
        <ChallengesSoumisList
          challenges={challengesSoumis}
          getChallengeName={getChallengeName}
        />
      )}

      <h2
        className="bg-custom-pink-entete text-custom-pink-entete  p-2 mb-2 mt-2 rounded-lg cursor-pointer  font-poppins flex items-center"
        onClick={() => setShowValidatedChallenges(!showValidatedChallenges)}
      >
        {showValidatedChallenges ? (
          <MinusCircleIcon className="h-5 w-5 mr-2" />
        ) : (
          <PlusCircleIcon className="h-5 w-5 mr-2" />
        )}
        Challenges Validés
      </h2>
      {showValidatedChallenges && (
        <ChallengesValidesList
          challenges={challengesValidés}
          getChallengeName={getChallengeName}
        />
      )}
    </div>
  );
};

const ChallengesList = ({ challenges, onOpenModal }) => {
  return (
    <>
      <div className="sm:hidden font-poppins">
        {challenges.map((challenge) => {
          const endDate = moment(challenge.fields.date_fin);
          const daysRemaining = endDate.diff(moment(), "days");
          // Condition pour vérifier si moins de 10 jours de validité restent
          const isLessThanTenDays = daysRemaining < 10;
          // Classe CSS conditionnelle
          const daysRemainingClass = isLessThanTenDays ? "text-red-500" : "";

          return (
            <div
              key={challenge.id}
              className="p-4 border-b bg-white shadow-sm rounded-lg m-3"
              onClick={() => onOpenModal(challenge)}
            >
              <strong>Nom du Challenge:</strong> {challenge.fields.nom}
              {/* <h3 className="font-bold">{challenge.fields.nom}</h3> */}
              <p className="mb-3 mt-3">
                <strong>Description:</strong>{" "}
                {challenge.fields.description
                  ? challenge.fields.description.length > 50
                    ? challenge.fields.description.slice(0, 50) + "..."
                    : challenge.fields.description
                  : "Aucune description disponible"}
              </p>
              {/* <p>{challenge.fields.description}</p> */}
              <p className="mb-3">
                <strong>Date d'expiration:</strong>{" "}
                {endDate.format("DD/MM/YYYY")}
              </p>
              {/* <p>Date de validité: {endDate.format("DD/MM/YYYY")}</p> */}
              <p className={daysRemainingClass}>
                {daysRemaining} jours restants
              </p>
              {/* <p>{daysRemaining} jours restants</p> */}
              <div className="text-center">
                <button className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-1 px-2 rounded mt-2">
                  Voir le challenge 👀
                </button>
              </div>
            </div>
          );
        })}
      </div>

      <div className="hidden sm:block overflow-x-auto  font-poppins">
        <table className="min-w-full table-auto border-collapse shadow-lg">
          <thead className="bg-pink-100">
            <tr>
              <th className="px-4 py-2 text-left">Nom</th>
              <th className="px-4 py-2 text-left">Description</th>
              <th className="px-4 py-2 text-left">Date d'expiration</th>
              <th className="px-4 py-2 text-left">Jours Restants</th>
              <th className="px-4 py-2">Action</th>
            </tr>
          </thead>

          <tbody>
            {challenges.map((challenge) => {
              const endDate = moment(challenge.fields.date_fin);
              const daysRemaining = endDate.diff(moment(), "days");
              return (
                <tr
                  key={challenge.id}
                  onClick={() => onOpenModal(challenge)}
                  className="cursor-pointer hover:bg-pink-100"
                >
                  <td className="border px-4 py-2">{challenge.fields.nom}</td>
                  {/* TODO: gerer l'overflow de texte */}

                  <td className="border px-4 py-2">
                    {challenge.fields.description
                      ? challenge.fields.description.length > 50
                        ? challenge.fields.description.slice(0, 50) + "..."
                        : challenge.fields.description
                      : "Aucune description disponible"}
                  </td>
                  

                  <td className="border px-4 py-2">
                    {endDate.format("DD/MM/YYYY")}
                  </td>
                  <td className="border px-4 py-2">{daysRemaining} jours</td>

                  <td className="border px-4 py-2 flex justify-center items-center">
                    <button
                      onClick={() => onOpenModal(challenge)}
                      className="bg-pink-500 hover:bg-pink-700 text-white font-bold py-1 px-2 rounded"
                    >
                      Voir le challenge 👀
                    </button>
                  </td>

                  {/* <h3>{challenge.fields.nom}</h3>
              <p>{challenge.fields.description}</p>
              {challenge.fields.photos && (
                <img
                  src={challenge.fields.photos[0].url}
                  alt={challenge.fields.nom}
                />
              )}
              <p>
                Date de début:{" "}
                {moment(challenge.fields.date_debut).format("DD/MM/YYYY")}
              </p>
              <p>
                Date de fin:{" "}
                {moment(challenge.fields.date_fin).format("DD/MM/YYYY")}
              </p>
              <button onClick={() => onOpenModal(challenge)}>Soumettre</button> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const ModalChallenge = ({
  challenge, // au lieu de selectedChallenge
  onClose, // au lieu de handleCloseModal
  onPhotoUpload, // utilisez ce nom tel quel
  setPhotoFile, // utilisez ce nom tel quel
  isModalOpen,
}) => {
  const endDate = moment(challenge.fields.date_fin)
  const daysRemaining = endDate.diff(moment(), "days");
  return (
<div className="overlay bg-gray-900 bg-opacity-50 flex justify-center items-center fixed inset-0 z-50 font-poppins">
      {isModalOpen && (
    <div className="modal bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-3/4 md:w-1/2 m-auto justify-center overflow-y-auto max-h-[90vh]">
    <div className="flex justify-between items-center mb-4">

        {/* Titre de la modal */}
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            {challenge.fields.nom}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          </div>

          <p className="text-gray-600 mb-4 font-poppins">
            {challenge.fields.description}
          </p>
          <p className="text-gray-600 mb-4 font-poppins">
            Date d'expiration : {endDate.format("DD/MM/YYYY")}
          </p>
          <p className="text-gray-600 mb-4 font-poppins">
            Nombre de jours restants : {daysRemaining} ⏱️
          </p>
          <hr className="border-t border-gray-300 my-4" />
          {/* Soumettre le Challenge */}
          <h3 className="text-xl font-semibold text-gray-800 mb-4">
            Je veux soumettre le Challenge 😎 ...
          </h3>

          <p className="text-gray-600 mb-4 font-poppins">
            Hé, on est pas du genre à se laisser convaincre facilement ! Montre-nous une preuve irréfutable : une photo, une capture, tout ce que tu as ! 💥📸😎
          </p>
          <p className="text-gray-600 mb-4 font-poppins">
            Formats acceptés: format images (PNG, JPG, etc...) ou PDF. Pour les vidéos, envoyez les nous sur Whatsapp SVP 🙏 Merciii 😊
          </p>
          <div className="mb-4">
            <input
              type="file"
              accept="*/*"
              onChange={(e) => setPhotoFile(e.target.files[0])}
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-pink-300"
            />
          </div>
          {/* Bouton pour l'upload de la photo */}
          <div  className="flex justify-between">

          <button
            onClick={onPhotoUpload}
            className="bg-pink-500 text-white py-2 px-4 rounded-md hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-pink-300"
          >
            Je soumets ce challenge 💪🥳
          </button>
          {/* Bouton pour fermer la modal */}
          <button
            onClick={onClose}
            className="bg-gray-200 text-gray-800 py-2 px-4 rounded-md ml-2 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
          >
            Annuler
          </button>
          </div>
        </div>
      )}
    </div>
  );
};

// const ChallengesSoumisList = ({ challenges, getChallengeName }) => {
//   return (
//     <div>
//       {challenges.map((submission) => (
//         <div key={submission.id}>
//           <h3>Nom du Challenge: {getChallengeName(submission)}</h3>
//           <p>Description: {submission.fields.description}</p>
//           {/* Autres détails de soumission */}
//         </div>
//       ))}
//     </div>
//   );
// };

const ChallengesValidesList = ({ challenges, getChallengeName }) => {
  return (
    // <div>
    //   {challenges.map((submission) => (
    //     <div key={submission.id}>
    //       <h3>Nom du Challenge: {getChallengeName(submission)}</h3>
    //       <p>Description: {submission.fields.description}</p>
    //       {/* Autres détails de soumission */}
    //     </div>
    //   ))}
    // </div>

    <>
      <div className="sm:hidden">
        {challenges.map((submission) => {
          return (
            <div
              key={submission.id}
              className="p-4 border-b bg-white shadow-sm rounded-lg m-2  font-poppins"
            >
              <h3>Nom du Challenge: {getChallengeName(submission)}</h3>
              {/* <p>Description: {submission.fields.description}</p> */}
            </div>
          );
        })}
      </div>

      <div className="hidden sm:block overflow-x-auto  font-poppins">
        <table className="min-w-full table-auto">
          <thead className="bg-pink-100">
            <tr>
              <th className="px-4 py-2 text-left">Nom</th>
              {/* <th className="px-4 py-2 text-left">Description</th> */}
            </tr>
          </thead>

          <tbody>
            {challenges.map((submission) => {
              return (
                <tr
                  key={submission.id}
                  className="cursor-pointer hover:bg-pink-100"
                >
                  <td className="border px-4 py-2">
                    {getChallengeName(submission)}
                  </td>
                  {/* TODO: gerer l'overflow de texte */}
                  {/* <td className="border px-4 py-2">
                    {submission.fields.description}
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AirtableData2;
